
export const tripStatusDesc = {
    0: $localize`:@@trip_status_codes:Trip Status`, // TripActionStatus.TripStatus
    1: $localize`:@@trip_status_unassiged:Not Launched`, // TripActionStatus.Unassigned
    2: $localize`:@@trip_status_codes:Closed Trips`, // TripActionStatus.Closed
}

export const tripStatesDesc = {
    1: $localize`:@@trip_state_pending:Pending`, // TripState.Pending
    2: $localize`:@@trip_state_in_progress:In Progress`, // TripState.InProgress
    3: $localize`:@@trip_state_completed:Completed`, // TripState.Completed
}

export const trackerTypesDesc = {
    1: $localize`:@@fleet_tracker_type_is3PL:3PL`, // FleetTrakerType.Is3PL
    0: $localize`:@@fleet_tracker_type_non_3pl:Non-3PL`, // FleetTrakerType.Non3PL
}