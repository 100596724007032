<form modal-body name="frmFleetReport" #ScheduleReportForm="ngForm" class=" py-2" emrForm autocomplete="off"
    [ngClass]="{ 'ng-submitted': isFormSubmitted}">
    <div class="col-12 report-filter-div">
        <ul class="nav report-div justify-content-left btn-list">
            <ng-container *ngIf="selectedReportType === reportType.FleetCurrentStatus">
                <li class="nav-item width-260">
                    <emr-custom-dropdown identifier="rptTimePeriodFilter" name="rptTimePeriodFilter" label="Time Period"
                        i18n-label="@@label_time_period" [options]="periodList" [(ngModel)]="reportParams.TimePeriod"
                        cssClass="default-dropdown" (ngModelChange)="onPeriodChange()" optionsValueField="Period"
                        optionsTextField="DisplayName" [inline]=true labelColumns=5 controlColumns="7"
                        [controlInline]=false labelCssClass="period-lable-css " inlineCss="pl-0">
                    </emr-custom-dropdown>
                </li>
                <li *ngIf="reportParams.TimePeriod === 8" class="nav-item">
                    <emr-datetimepicker name="txtStartDate" formGroupCssClass="mb-0" identifier="txtStartDate"
                        ngDefaultControl [(ngModel)]="reportParams.FromDate" placeholder="Start Date"
                        i18n-placeholder="@@link_start_date" required CustomizeGrid="true" [containerValue]="''"
                        datePickerDivCssClass="width-125 px-0" (ngModelChange)="onDateChanged()"
                        [showDateTimeInLine]="true" [dateTimeObject]=dateTimeObject [setExtremeTimes]="true"
                        timePickerDivCssClass="width-100 ml-1 px-0" [isDisabled]="reportParams.TimePeriod !== 8"
                        cssClass="calendar-text" i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM"
                        [minDate]="minDate" [maxDate]="maxDate" [EmrInvalidValidator]="isDateRangeValid"
                        dateTimePickerDivCss="report-date-div {{ dateTimeObject.showMeridian ? '' : ' margin-r5'}}"
                        [showValidationInNewLine]=true validationCss="px-0">
                        <emr-validation-message validatorName="required" message="Start Date/Time Required"
                            i18n-message="@@start_date_required">
                        </emr-validation-message>
                        <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;">
                        </emr-validation-message>
                    </emr-datetimepicker>
                </li>
                <li *ngIf="reportParams.TimePeriod === 8" class="nav-item">
                    <emr-datetimepicker name="txtEndDate" formGroupCssClass="mb-0" ngDefaultControl
                        identifier="txtEndDate" required [(ngModel)]="reportParams.ThruDate" required
                        placeholder="End Date" i18n-placeholder="@@link_end_date" CustomizeGrid="true"
                        [showPickerOnTop]="true" cssClass="calendar-text" [minDate]="reportParams.FromDate ? reportParams.FromDate : minDate" [maxDate]="maxDate"
                        [dateTimeObject]=dateTimeObject [containerValue]="''" [showDateTimeInLine]="true"
                        i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" [setEndTime]="true"
                        [setExtremeTimes]="true" (ngModelChange)="onDateChanged()"
                        dateTimePickerDivCss="report-date-div  {{ dateTimeObject.showMeridian ? '' : ' margin-r5'}}"
                        datePickerDivCssClass="width-125 px-0" timePickerDivCssClass="width-100 ml-1 px-0"
                        [isDisabled]="reportParams.TimePeriod !== 8" [EmrInvalidValidator]="isDateRangeValid"
                        [showValidationInNewLine]=true validationCss="px-0">
                        <emr-validation-message validatorName="required" message="End Date/Time Required"
                            i18n-message="@@end_date_required">
                        </emr-validation-message>
                        <emr-validation-message validatorName="EmrInvalidValidator" i18n-message="@@greater_end_time"
                            message="End time must be greater than Start time">
                        </emr-validation-message>
                    </emr-datetimepicker>
                </li>
                <li class="nav-item width-260">
                    <emr-custom-dropdown identifier="ddlTripStatus" name="ddlTripStatus" label="Shipment Status"
                        i18n-label="@@label_trip_status" [options]="tripStatusList"
                        [(ngModel)]="reportParams.TripStatus" cssClass="default-dropdown" [inline]=true labelColumns=6
                        controlColumns="6" [controlInline]=false labelCssClass="period-lable-css " inlineCss="pl-0"
                        (ngModelChange)="onTripStatusChange()">
                    </emr-custom-dropdown>
                </li>
                <li class="fleet-multiselect width-210 nav-item">
                    <emr-multiselect-dropdown identifier="lstTripStates" name="lstTripStates" [options]="tripStateList"
                        label="Status" i18n-label="@@status" [(ngModel)]="reportParams.TripState" [inline]=true
                        [controlInline]=false labelColumns=3 controlColumns=8 selectAllText="Select All"
                        i18n-selectAllText="@@select_all" unSelectAllText="UnSelect All" optionsValueField="value"
                        optionsTextField="text" [itemsShowLimit]=1 [allowSearchFilter]=false labelCssClass="period-lable-css "
                        i18n-unSelectAllText="@@unselect_all" (onItemSelect)="onTripStateChange()"
                        [required]="this.reportParams.TripStatus === 0" (ngModelChange)="onTripStateChange()">
                    </emr-multiselect-dropdown>
                </li>
                <li class="nav-item w-auto">
                    <emr-checkbox ngDefaultControl identifier="chkBoxShowNextStop" (change)="onShowNextStopChange()"
                        name="chkBoxShowNextStop" text="Show next stop info" i18n-text="@@label_show_next_stop_info"
                        [(ngModel)]="reportParams.ShowNextStopInfo">
                    </emr-checkbox>
                </li>
            </ng-container>
            <li *ngIf="selectedReportType === reportType.InProgressShipment" class="nav-item w-auto">
                <emr-checkbox ngDefaultControl identifier="chkBoxOutOfTempRange" (change)="onOutOfTempRangeChange()"
                    name="chkBoxOutOfTempRange" text="Only show out of temperature range"
                    i18n-text="@@label_temp_out_of_range" [(ngModel)]="reportParams.OutOfTempRange">
                </emr-checkbox>
            </li>
            <!-- <ng-container *ngIf="selectedReportType === reportType.FleetCurrentStatusAggregate">
                <li class="nav-item">
                    <emr-custom-dropdown identifier="ddlTrackerType" name="ddlTrackerType" label="Tracker Type"
                        i18n-label="@@label_tracker_type" [options]="trackerTypeList" [(ngModel)]="reportParams.Is3PL"
                        cssClass="default-dropdown" [inline]=true labelColumns=5 controlColumns="7"
                        [controlInline]=false labelCssClass="period-lable-css " inlineCss="pl-0"
                        (ngModelChange)="onFleetTrakerTypeChange()" placeholder="All" i18n-placeholder="@@label_all">
                    </emr-custom-dropdown>
                </li>
                <li class="nav-item w-auto">
                    <emr-checkbox ngDefaultControl identifier="chkBoxShowInactive" (change)="onShowInactiveChange()"
                        name="chkBoxShowInactive" text="Show Inactive" i18n-text="@@label_show_inactive"
                        [(ngModel)]="reportParams.ShowInactive">
                    </emr-checkbox>
                </li>
                <li class="nav-item w-auto">
                    <emr-checkbox ngDefaultControl identifier="chkShowTags" (change)="onShowTagsChange()"
                        name="chkShowTags" text="Show Smart Tags" i18n-text="@@label_show_smart_tags"
                        [(ngModel)]="reportParams.ShowTags">
                    </emr-checkbox>
                </li>
                <li class="nav-item w-auto">
                    <emr-checkbox ngDefaultControl identifier="chkTraxxGoOnly" (change)="onTraxxGoOnlyChange()"
                        name="chkTraxxGoOnly" text="Traxx Go Only" i18n-text="@@label_traxx_go_only"
                        [(ngModel)]="reportParams.TraxxGoOnly">
                    </emr-checkbox>
                </li>
            </ng-container> -->
        </ul>
    </div>
</form>